import dynamic from "next/dynamic";
import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react";
import { AlertColor, AlertModalDialogProps } from "./alert-modal-dialog";

const Content = dynamic<AlertModalDialogProps>(
    () => import("./alert-modal-dialog").then((component) => component.AlertModalDialog),
    {
        ssr: false,
    },
);

export interface AlertModalProps {
    message?: string;
    onClose?: () => void;
    onCloseLabel?: string;
    onSubmit: () => void;
    onSubmitLabel?: string;
    title: string;
    type?: AlertColor;
}

export interface AlertModalRef {
    close: () => void;
    open: (modal: AlertModalProps) => void;
}

export const AlertModal = forwardRef<AlertModalRef>((props, ref) => {
    const [modal, setModal] = useState<AlertModalProps | null>();

    const open = useCallback((newModal: AlertModalProps) => setModal(newModal), []);

    const close = useCallback(() => setModal(null), []);

    const innerRef = useRef<AlertModalRef>({
        close,
        open,
    });

    useImperativeHandle(
        ref,
        () => ({
            close: innerRef.current.close,
            open: innerRef.current.open,
        }),
        [],
    );

    const onClose = useCallback(() => {
        setModal((prev) => {
            if (prev?.onClose) {
                prev.onClose();
            }
            return null;
        });
    }, []);

    const onSubmit = useCallback(() => {
        setModal((prev) => {
            if (prev) {
                prev.onSubmit();
            }
            return null;
        });
    }, []);

    return modal ? (
        <Content
            title={modal.title}
            message={modal.message}
            onSubmit={onSubmit}
            open={!!modal}
            onSubmitLabel={modal.onSubmitLabel}
            onClose={onClose}
            onCloseLabel={modal.onCloseLabel}
            type={modal.type}
        />
    ) : null;
});

AlertModal.displayName = "AlertModal";
