import { ApolloProvider } from "@apollo/client";
import { NextPage } from "@app-routes";
import { useApollo } from "@shared/lib/apollo/apollo-client";
import { LastLocationProvider, useUpdateLastLocation } from "@shared/lib/last-location/last-location";
import { DeviceTypeProvider, useInitializeDeviceType } from "@shared/lib/ua";
import { getAlertModalRef } from "@shared/services/alert-modal-service";
import { getFlashMessagesRef } from "@shared/services/flash-messages-service";
import { AlertModal } from "@ui/components/alert-modal/alert-modal";
import { FlashMessages } from "@ui/components/flash-messages/flash-messages";
import "@ui/styles/globals.css";
import { extend, locale } from "dayjs";
import "dayjs/locale/cs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import "focus-visible";
import { AppProps } from "next/app";
import React from "react";

type AppPropsWithLayout = AppProps & {
    Component: NextPage<any>;
};

function UxfApp(props: AppPropsWithLayout) {
    extend(localizedFormat);
    locale("cs");

    const { Component, pageProps } = props;
    const apollo = useApollo(pageProps);
    const deviceType = useInitializeDeviceType(pageProps);
    const lastLocation = useUpdateLastLocation(Component.noHistory);

    return (
        <ApolloProvider client={apollo}>
            <DeviceTypeProvider value={deviceType}>
                <LastLocationProvider value={lastLocation}>
                    <Component {...pageProps} />
                    <FlashMessages ref={getFlashMessagesRef()} />
                    <AlertModal ref={getAlertModalRef()} />
                </LastLocationProvider>
            </DeviceTypeProvider>
        </ApolloProvider>
    );
}

export default UxfApp;
