export const config = {
    DEBUG: process.env.STAGE === "local",
    FACEBOOK_APP_ID: process.env.NEXT_PUBLIC_FACEBOOK_APP_ID,
    FRONTEND_URL: process.env.NEXT_PUBLIC_FRONTEND_URL,
    GA_ID: process.env.GOOGLE_CLIENT_ID,
    GTM_ID:
        process.env.NEXT_PUBLIC_GTM_ID && process.env.NEXT_PUBLIC_GTM_ID !== "" ? process.env.NEXT_PUBLIC_GTM_ID : null,
    NODE_ENV: process.env.NODE_ENV,
    STAGE: process.env.STAGE as "local" | "dev" | "prod" | "pre-prod" | "pov-dev" | "sandbox",
};
