import { AlertModalProps, AlertModalRef } from "@ui/components/alert-modal/alert-modal";
import { createRef } from "react";

const alertModalRef = createRef<AlertModalRef>();

export function getAlertModalRef() {
    return alertModalRef;
}

export function alertModal(modal: AlertModalProps) {
    if (alertModalRef.current) {
        alertModalRef.current.open(modal);
    }
}

export function closeAlertModal() {
    if (alertModalRef.current) {
        alertModalRef.current.close();
    }
}
