import { useRouter } from "@app-routes";
import { createContext, useContext, useEffect, useState } from "react";

export function useUpdateLastLocation(notAllowed?: boolean) {
    const [lastLocation, setLastLocation] = useState("");

    const { asPath, events } = useRouter();

    useEffect(() => {
        const handler = () => setLastLocation(notAllowed ? "" : asPath);
        events.on("routeChangeStart", handler);
        return () => events.off("routeChangeStart", handler);
    }, [asPath, events, notAllowed]);

    return lastLocation;
}

const LastLocationContext = createContext<string | undefined>(undefined);

export const LastLocationProvider = LastLocationContext.Provider;

export function useLastLocation() {
    const { asPath } = useRouter();
    const lastLocation = useContext(LastLocationContext);

    const pureAsPath = asPath.split("?")[0];
    const pureLastLocation = lastLocation?.split("?")[0];

    if (!lastLocation || pureLastLocation === pureAsPath) {
        return null;
    }
    return lastLocation;
}
