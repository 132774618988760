import { Cookie } from "@uxf/core/cookie";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import UAParser from "ua-parser-js";

const DEVICE_TYPE_COOKIE = "device-type";

const DEVICE_TYPES = {
    mobile: "mobile",
    desktop: "desktop",
} as const;

type DeviceType = keyof typeof DEVICE_TYPES;

function normalizeDeviceType(deviceType: any): DeviceType | undefined {
    return typeof deviceType === "string" && Object.keys(DEVICE_TYPES).includes(deviceType)
        ? DEVICE_TYPES[deviceType as DeviceType]
        : undefined;
}

const MOBILE_DEVICES = ["mobile", "tablet", "wearable"];

function getDeviceTypeFromUA(ctx?: any): DeviceType {
    const ua: string =
        ctx?.req?.headers["user-agent"] || (typeof navigator !== "undefined" && navigator.userAgent) || "";
    if (ua) {
        const deviceType = new UAParser(ua).getDevice().type || "";
        return MOBILE_DEVICES.includes(deviceType) ? DEVICE_TYPES.mobile : DEVICE_TYPES.desktop;
    }
    return DEVICE_TYPES.mobile;
}

export function getDeviceType(ctx?: any): DeviceType {
    const cookie = Cookie.create(ctx);
    const result = normalizeDeviceType(cookie.get(DEVICE_TYPE_COOKIE)) || getDeviceTypeFromUA(ctx);
    cookie.set(DEVICE_TYPE_COOKIE, result);
    return result;
}

export function useInitializeDeviceType(pageProps?: any): DeviceType {
    const deviceType = normalizeDeviceType(pageProps?.deviceType);

    const [newDeviceType, setNewDeviceType] = useState(deviceType || DEVICE_TYPES.mobile);

    useEffect(() => {
        const cookie = Cookie.create();
        const deviceTypeCookie = normalizeDeviceType(cookie.get(DEVICE_TYPE_COOKIE));
        if (deviceTypeCookie && deviceType !== deviceTypeCookie) {
            setNewDeviceType(deviceTypeCookie);
        } else if (!deviceType) {
            const result = getDeviceTypeFromUA();
            cookie.set(DEVICE_TYPE_COOKIE, result);
            setNewDeviceType(result);
        }
    }, [deviceType]);

    return newDeviceType;
}

const DeviceTypeContext = createContext<DeviceType | undefined>(undefined);

export const DeviceTypeProvider = DeviceTypeContext.Provider;

export const useChangeDeviceType = (): [DeviceType | undefined, () => void] => {
    const deviceType = useContext(DeviceTypeContext);

    const changeDeviceType = useCallback(() => {
        const cookie = Cookie.create();
        const deviceTypeCookie = normalizeDeviceType(cookie.get(DEVICE_TYPE_COOKIE));
        if (deviceTypeCookie) {
            cookie.set(
                DEVICE_TYPE_COOKIE,
                deviceTypeCookie === DEVICE_TYPES.mobile ? DEVICE_TYPES.desktop : DEVICE_TYPES.mobile,
            );
            location.reload();
        }
    }, []);

    return [deviceType, changeDeviceType];
};

export const useDeviceType = () => useContext(DeviceTypeContext);

export const useIsMobile = () => useContext(DeviceTypeContext) === DEVICE_TYPES.mobile;

export const useIsDesktop = () => useContext(DeviceTypeContext) === DEVICE_TYPES.desktop;
