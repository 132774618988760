import { Notification } from "@ui/components/flash-messages/flash-message";
import { FlashMessagesRef } from "@ui/components/flash-messages/flash-messages";
import { createRef } from "react";

const flashMessagesRef = createRef<FlashMessagesRef>();

export function getFlashMessagesRef() {
    return flashMessagesRef;
}

export function flashMessage(notification: Notification) {
    if (flashMessagesRef.current) {
        flashMessagesRef.current.open(notification);
    }
}

export function closeAllFlashMessages() {
    if (flashMessagesRef.current) {
        flashMessagesRef.current.closeAll();
    }
}

export function successMessage(message: string) {
    return flashMessage({ message, variant: "success" });
}

export function errorMessage(message: string) {
    return flashMessage({ message, variant: "error" });
}
