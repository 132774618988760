import {
    EventBlockDetailTabName,
    EventDetailTabName,
    LevelDetailTabName,
    ParentPlayerDetailTabName,
    PlayerDetailTabName,
    StaffCalendarTabName,
} from "@shared/utils/tab-helper";

export type Routes = {
    // main zone
    "main-zone/index": null;
    "main-zone/unsupported-browser": null;
    "main-zone/login": { redirect?: string };
    "main-zone/forgotten-password": null;
    "main-zone/profile-not-found": null;
    "main-zone/logout": null;
    "main-zone/password-set": { token: string };

    // parent zone - calendar
    "parent-zone/calendar": { date?: string; month?: string };
    "parent-zone/event-detail": { eventId: number };
    "parent-zone/event-detail-excuse": { eventId: number; playerId: number };

    // parent zone - players
    "parent-zone/player-list": null;
    "parent-zone/player-detail": { playerId: number; t?: ParentPlayerDetailTabName };

    // parent zone - badge
    "parent-zone/level-list": null;
    "parent-zone/level-detail": { levelId: number; t?: LevelDetailTabName };
    "parent-zone/badge-detail": { levelId: number; badgeId: number };

    // parent zone - my profile
    "parent-zone/my-profile": null;
    "parent-zone/navigation": null;

    // staff zone - profile
    "staff-zone/my-profile": { clubId: number };

    // staff zone - player
    "staff-zone/staff-player-list": { clubId: number };
    "staff-zone/staff-prizes-list": { clubId: number };
    "staff-zone/staff-player-create": { clubId: number };
    "staff-zone/staff-player-update": { clubId: number; playerClubId: number; formData?: string };
    "staff-zone/staff-player-update-parents": { clubId: number; playerClubId: number };
    "staff-zone/staff-player-detail": { clubId: number; playerClubId: number; t?: PlayerDetailTabName };
    "staff-zone/staff-player-update-alert": { clubId: number; playerClubId: number; formData: string };

    // staff zone - parent
    "staff-zone/staff-parent-list": { clubId: number };
    "staff-zone/staff-parent-create": { clubId: number };
    "staff-zone/staff-parent-update": { clubId: number; parentId: number };
    "staff-zone/staff-parent-detail": { clubId: number; parentId: number };
    "staff-zone/staff-parent-players": { clubId: number; parentId: number };

    // staff zone - staff
    "staff-zone/staff-staff-list": { clubId: number };
    "staff-zone/staff-staff-create": { clubId: number };
    "staff-zone/staff-staff-update": { clubId: number; staffId: number };
    "staff-zone/staff-staff-detail": { clubId: number; staffId: number };

    // staff zone - badge
    "staff-zone/level-list": { clubId: number };
    "staff-zone/level-detail": { clubId: number; levelId: number; t?: LevelDetailTabName };
    "staff-zone/badge-detail": { clubId: number; levelId: number; badgeId: number };

    // staff zone - prizes
    "staff-zone/prize-list": { clubId: number };

    // staff zone - navigation
    "staff-zone/navigation": { clubId: number };

    // staff zone - calendar
    "staff-zone/staff-calendar": { clubId: number; date?: string; month?: string; t?: StaffCalendarTabName };
    "staff-zone/one-event-create": { clubId: number; date?: string };
    "staff-zone/event-detail": { clubId: number; eventId: number; t?: EventDetailTabName };
    "staff-zone/event-detail-add-player": { clubId: number; eventId: number; levelId: number };
    "staff-zone/event-update": { clubId: number; eventId: number };
    "staff-zone/event-update-description": { clubId: number; eventId: number; isPublic: boolean };
    "staff-zone/event-detail-badges": { clubId: number; eventId: number };
    "staff-zone/event-detail-player-badges": { clubId: number; eventId: number; playerClubId: number };
    "staff-zone/event-detail-rating": { clubId: number; eventId: number; playerClubId: number };
    "staff-zone/event-detail-bulk-player-rating": { clubId: number; eventId: number };
    "staff-zone/event-update-staffs": { clubId: number; eventId: number };

    // uikit zone
    "uikit-zone/index": null;

    // event zone
    "event-zone/event-block-list": { clubId: number };
    "event-zone/event-block-detail": { clubId: number; eventBlockId: number; t?: EventBlockDetailTabName };
    "event-zone/event-block-create": { clubId: number };
    "event-zone/event-block-update": { clubId: number; eventBlockId: number };
    "event-zone/event-block-update-players": { clubId: number; eventBlockId: number };
    "event-zone/event-block-update-staffs": { clubId: number; eventBlockId: number };
};

export const routes: { [route in keyof Routes]: string } = {
    // main zone
    "main-zone/index": "/",
    "main-zone/unsupported-browser": "/nepodporovany-prohlizec",
    "main-zone/login": "/prihlaseni",
    "main-zone/forgotten-password": "/zapomenute-heslo",
    "main-zone/profile-not-found": "/profil-neexistuje",
    "main-zone/logout": "/logout",
    "main-zone/password-set": "/nastavit-heslo/[token]",

    // parent zone - calendar
    "parent-zone/calendar": "/rodic/kalendar",
    "parent-zone/event-detail": "/rodic/udalost/[eventId]",
    "parent-zone/event-detail-excuse": "/rodic/udalost/[eventId]/omluvenka",

    // parent zone - players
    "parent-zone/player-list": "/rodic/hraci",
    "parent-zone/player-detail": "/rodic/hrac/[playerId]",

    // parent zone - badge
    "parent-zone/level-list": "/rodic/metodika",
    "parent-zone/level-detail": "/rodic/metodika/uroven/[levelId]",
    "parent-zone/badge-detail": "/rodic/metodika/uroven/[levelId]/odznak/[badgeId]",

    // parent zone - my profile
    "parent-zone/my-profile": "/rodic/muj-profil",
    "parent-zone/navigation": "/rodic/navigace",

    // staff zone - profile
    "staff-zone/my-profile": "/staff/[clubId]/muj-profil",

    // staff zone - prizes
    "staff-zone/prize-list": "/staff/[clubId]/prize-list",

    // staff zone - player
    "staff-zone/staff-player-list": "/staff/[clubId]/players",
    "staff-zone/staff-prizes-list": "/staff/[clubId]/prize-list",
    "staff-zone/staff-player-create": "/staff/[clubId]/player/add",
    "staff-zone/staff-player-update": "/staff/[clubId]/player/[playerClubId]/edit",
    "staff-zone/staff-player-update-parents": "/staff/[clubId]/player/[playerClubId]/parents",
    "staff-zone/staff-player-detail": "/staff/[clubId]/player/[playerClubId]/info",
    "staff-zone/staff-player-update-alert": "/staff/[clubId]/player/[playerClubId]/staff-player-update-alert",

    // staff zone - parent
    "staff-zone/staff-parent-list": "/staff/[clubId]/parents",
    "staff-zone/staff-parent-create": "/staff/[clubId]/parent/add",
    "staff-zone/staff-parent-update": "/staff/[clubId]/parent/[parentId]/edit",
    "staff-zone/staff-parent-detail": "/staff/[clubId]/parent/[parentId]/info",
    "staff-zone/staff-parent-players": "/staff/[clubId]/parent/[parentId]/players",

    // staff zone - staff
    "staff-zone/staff-staff-list": "/staff/[clubId]/staffs",
    "staff-zone/staff-staff-create": "/staff/[clubId]/staff/add",
    "staff-zone/staff-staff-update": "/staff/[clubId]/staff/[staffId]/edit",
    "staff-zone/staff-staff-detail": "/staff/[clubId]/staff/[staffId]/info",

    // staff-zone - methodology
    "staff-zone/level-list": "/staff/[clubId]/metodika",
    "staff-zone/level-detail": "/staff/[clubId]/metodika/uroven/[levelId]",
    "staff-zone/badge-detail": "/staff/[clubId]/metodika/uroven/[levelId]/odznak/[badgeId]",

    // staff-zone - calendar
    "staff-zone/staff-calendar": "/staff/[clubId]/kalendar",
    "staff-zone/one-event-create": "/staff/[clubId]/event/add",
    "staff-zone/event-detail": "/staff/[clubId]/kalendar/[eventId]",
    "staff-zone/event-detail-add-player": "/staff/[clubId]/kalendar/[eventId]/[levelId]/pridat-hrace",
    "staff-zone/event-update-staffs": "/staff/[clubId]/kalendar/[eventId]/treneri",
    "staff-zone/event-update-description": "/staff/[clubId]/kalendar/[eventId]/upravit-popis",
    "staff-zone/event-update": "/staff/[clubId]/kalendar/[eventId]/edit",
    "staff-zone/event-detail-badges": "/staff/[clubId]/kalendar/[eventId]/odznaky",
    "staff-zone/event-detail-player-badges": "/staff/[clubId]/kalendar/[eventId]/hrac/[playerClubId]/odznaky",
    "staff-zone/event-detail-rating": "/staff/[clubId]/kalendar/[eventId]/hodnoceni/[playerClubId]",
    "staff-zone/event-detail-bulk-player-rating": "/staff/[clubId]/kalendar/[eventId]/hromadne-hodnoceni",

    "staff-zone/navigation": "/staff/[clubId]/navigace",

    // uikit zone
    "uikit-zone/index": "/uikit",

    // event zone
    "event-zone/event-block-list": "/staff/[clubId]/event-block",
    "event-zone/event-block-detail": "/staff/[clubId]/event-block/[eventBlockId]",
    "event-zone/event-block-create": "/staff/[clubId]/event-block/add",
    "event-zone/event-block-update": "/staff/[clubId]/event-block/[eventBlockId]/edit",
    "event-zone/event-block-update-players": "/staff/[clubId]/event-block/[eventBlockId]/hraci",
    "event-zone/event-block-update-staffs": "/staff/[clubId]/event-block/[eventBlockId]/treneri",
};
