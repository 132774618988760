import { Router as CoreRouter, FunctionParametersGenerator, PropsGenerator, UxfNextPage } from "@uxf/router";
import { Routes, routes } from "./routes";

const router = new CoreRouter<Routes>(routes);

export const useRouter = router.getUseRouter();
export const Link = router.getLink();
export const Router = router.getSingletonRouter();

export type RouteProps = PropsGenerator<Routes>;
export type RouteParams = FunctionParametersGenerator<Routes>;
export type NextPage<Route extends keyof Routes, IP = Record<string, any>, P = IP> = UxfNextPage<
    Routes,
    Route,
    IP,
    P
> & { noHistory?: boolean };

export const getRoutesForSitemap = () => routes;
